@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;1,200;1,300;1,500&display=swap');
/* :root {
	--main-bg-color: #fff;
	--main-header-color: #fff;
	--main-font-color: rgba(34, 33, 33);
	--additional-color: #ff4f79;
} */
/* :root {
	--main-bg-color: #06051e;
	--main-header-color: #222146;
	--main-font-color: #fff;
	--additional-color: #ff4f79;
} */

*,
*::before,
*::after {
	margin: 0;
	padding: 0;
	box-sizing: inherit;
}

html {
	font-family: 'Poppins', sans-serif;
	font-size: 10px;
	box-sizing: border-box;
}

body {
	width: 100%;
	/* height: 100vh; */
	background-color: var(--main-bg-color);
}

header {
	z-index: 1000;
}

@media screen and (min-width: 951px) {
	header {
		position: fixed;
		top: 0;
		width: 100vw;
		background-color: var(--main-header-color);

		border-bottom: 1px solid var(--footer-bkg);
	}
	.containerHeader {
		position: relative;
		width: 100%;
		max-width: 1000px;
		margin: 0 auto;
		padding: 0 1.5rem;
	}

	nav {
		width: 100%;
		height: 8rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.navbar-brand {
		width: 16rem;
		/* height: 6rem; */
		display: flex;
		transform: scale(1);
		transition: all 1s;
		margin: auto 30px auto 0;
	}
	.navbar-brand.active {
		transform: scale(1);
	}
	.navbar-brand i {
		font-size: 4rem;
		margin: auto;
	}
	.navbar-brand .imgHead {
		font-size: 10rem;
		margin: auto;
		margin-left: 15px;
		/* max-width: 150px; */
		width: 22rem;
	}
	.nav-title {
		display: block;
		left: 0;
		color: var(--main-font-color);
		font-size: 3rem;
		transform: scale(1);
		transition: all 1s;
		width: 35vw;
		font-family: "Poppins", sans-serif;
		font-weight: 200;
		font-style: italic;
	}
	.nav-title.active {
		transform: scale(0);
	}
	.nav-title span {
		color: var(--additional-color);
		font-family: "Poppins", sans-serif;
	}
	.nav-list-desktop {
		display: flex;
		list-style: none;
		width: 55vw;
		justify-content: flex-end;
		z-index: 1500;
		/* background: red; */
	}
	.nav-link {
		text-decoration: none;
		display: block;
		margin: 16px 2rem 0 2rem;

		color: var(--main-font-color);
		color: black;
		transform: scale(1);
		transition: transform 650ms;
	}
	.nav-item {
		position: relative;
		line-height: 8rem;
		font-size: 1.7rem;
		transition-delay: .5s;
		/* visibility: hidden; */
		margin-top: 0;
	}
	.nav-item::before {
		content: '';
		position: absolute;
		width: 100%;
		height: 6px;
		background-color: var(--additional-color);
		left: 0;
		bottom: 0;
		transform: scaleX(0);
		transform-origin: right;
		transition: transform 650ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
	}
	.nav-item:hover::before {
		transform: scaleX(1);
		transform-origin: left;
	}

	.nav-list,
	.nav-list.active {
		display: none;
	} /*
	.nav-list-desktop,
	.nav-link {
		transform: scale(1);
	}

	.nav-list-desktop .nav-item {
		transition-delay: initial;
		visibility: visible;
	}
	
	.nav-list-desktop .nav-item:nth-child(1) .nav-link {
		transition-delay: .5s;
	}
	.nav-list-desktop .nav-item:nth-child(2) .nav-link {
		transition-delay: .4s;
	}
	.nav-list-desktop .nav-item:nth-child(3) .nav-link {
		transition-delay: .3s;
	}
	.nav-list,
	.nav-list.active .nav-item:nth-child(4) .nav-link {
		transition-delay: .2s;
	}
	.nav-list,
	.nav-list.active .nav-item:nth-child(5) .nav-link {
		transition-delay: .1s;
	}

	.nav-list,
	.nav-list .nav-item:nth-child(1) .nav-link {
		transition-delay: .1s;
	}
	.nav-list,
	.nav-list .nav-item:nth-child(2) .nav-link {
		transition-delay: .2s;
	}
	.nav-list,
	.nav-list .nav-item:nth-child(3) .nav-link {
		transition-delay: .3s;
	}
	.nav-list,
	.nav-list .nav-item:nth-child(4) .nav-link {
		transition-delay: .4s;
	}
	.nav-list,
	.nav-list .nav-item:nth-child(5) .nav-link {
		transition-delay: .5s;
	} */

	.hamburger {
		display: none;
		/* width: 3.6rem;
	height: 1.8rem;
	position: relative;
	cursor: pointer;
	line-height: 5rem;
	z-index: 1500; */
	}
}
/* 
.hamburger .line {
	width: 100%;
	height: 2px;
	background-color: var(--main-font-color);
	position: absolute;
	display: block;
	right: 10px;
	transition: all 650ms;
}
.hamburger .line:nth-child(1) {
	top: 0;
}
.hamburger .line:nth-child(2) {
	top: 50%;
	margin-top: -1px;
	width: 2.8rem;
}
.hamburger .line:nth-child(3) {
	top: 100%;
	margin-top: -2px;
	width: 2rem;
}
.hamburger.active .line:nth-child(1) {
	transform: translateY(.8rem) rotate(45deg);
	width: 2rem;
}
.hamburger.active .line:nth-child(2) {
	opacity: 0;
}
.hamburger.active .line:nth-child(3) {
	transform: translateY(-.8rem) rotate(-45deg);
	width: 2rem;
}

.nav-list.active .nav-link {
	transform: scale(1);
}

.nav-list.active .nav-item {
	transition-delay: initial;
	visibility: visible;
}
.nav-list.active .nav-item:nth-child(1) .nav-link {
	transition-delay: .5s;
}
.nav-list.active .nav-item:nth-child(2) .nav-link {
	transition-delay: .4s;
}
.nav-list.active .nav-item:nth-child(3) .nav-link {
	transition-delay: .3s;
}
.nav-list.active .nav-item:nth-child(4) .nav-link {
	transition-delay: .2s;
}
.nav-list.active .nav-item:nth-child(5) .nav-link {
	transition-delay: .1s;
}

.nav-list .nav-item:nth-child(1) .nav-link {
	transition-delay: .1s;
}
.nav-list .nav-item:nth-child(2) .nav-link {
	transition-delay: .2s;
}
.nav-list .nav-item:nth-child(3) .nav-link {
	transition-delay: .3s;
}
.nav-list .nav-item:nth-child(4) .nav-link {
	transition-delay: .4s;
}
.nav-list .nav-item:nth-child(5) .nav-link {
	transition-delay: .5s;
} */

/* @media screen and (max-width: 950px) {
	.nav-list {
		width: 100%;
		height: 100vh;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background-color: var(--main-header-color);
		position: fixed;
		left: 0;
		bottom: -100vh;
		transition: bottom 650ms;

		margin: 0;
	}
	.nav-list.active {
		bottom: 0;
	}
	.navbar-brand {
		
		width: 28vh;
		height: 28vh;
		display: flex;
	}
	.navbar-brand.active {
		transform: scale(0);
	}
	.navbar-brand i {
		font-size: 4vw;
		color: var(--main-font-color);
	}
	.navbar-brand .imgHead {
		width: 8rem;
		margin-top: 6px;
	}
	h1.nav-title {
		font-size: 8vw;
		width: 45vw;
		letter-spacing: -0.5px;
	}
	.nav-item:hover::before {
		transform: scaleX(0);
		transform-origin: left;
	}
	.nav-link {
		font-size: 2.4rem;
	}
} */
@media screen and (max-width: 950px) {
	header {
		position: fixed;
		top: 0;
		width: 100vw;
		background-color: var(--main-header-color);

		border-bottom: 1px solid var(--footer-bkg);
	}
	.containerHeader {
		position: relative;
		width: 100%;
		max-width: 1000px;
		margin: 0 auto;
		padding: 0 1.5rem;
	}

	nav {
		width: 100%;
		height: 8rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.nav-title {
		font-size: 5vw;
		width: 65vw;
		font-family: "Poppins", sans-serif;
	}

	.navbar-brand .imgHead {
		width: 20rem;
		margin-top: 8px;
		margin-left: 30px;
	}
	.hamburger {
		display: inline-block;
		width: 3.6rem;
		height: 1.8rem;
		position: relative;
		cursor: pointer;
		line-height: 5rem;
		z-index: 1500;
		margin-right: 30px;
	}

	.hamburger .line {
		width: 100%;
		height: 2px;
		background-color: var(--main-font-color);
		position: absolute;
		display: block;
		right: 10px;
		transition: all 650ms;
	}
	.hamburger .line:nth-child(1) {
		top: 0;
	}
	.hamburger .line:nth-child(2) {
		top: 50%;
		margin-top: -1px;
		width: 2.8rem;
	}
	.hamburger .line:nth-child(3) {
		top: 100%;
		margin-top: -2px;
		width: 2rem;
	}
	.hamburger.active .line:nth-child(1) {
		transform: translateY(.8rem) rotate(45deg);
		width: 2rem;
	}
	.hamburger.active .line:nth-child(2) {
		opacity: 0;
	}
	.hamburger.active .line:nth-child(3) {
		transform: translateY(-.8rem) rotate(-45deg);
		width: 2rem;
	}
	.nav-list-desktop {
		display: none;
	}
	.nav-list {
		display: flex;
		width: 100%;
		height: 100vh;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background-color: var(--main-header-color);
		position: fixed;
		left: 0;
		bottom: -100vh;
		transition: bottom 650ms;
		list-style: none;
		margin: 0;
	}
	.nav-list.active {
		bottom: 0;
	}
	/* .nav-list {
		display: flex;
		list-style: none;
		width: 35vw;
		justify-content: flex-end;
	}
	.nav-list.active {
		width: 100vw;
	} */
	.nav-list.active .nav-link {
		transform: scale(1);
	}

	.nav-list.active .nav-item {
		transition-delay: initial;
		visibility: visible;
	}
	.nav-list.active .nav-item:nth-child(1) .nav-link {
		transition-delay: .5s;
	}
	.nav-list.active .nav-item:nth-child(2) .nav-link {
		transition-delay: .4s;
	}
	.nav-list.active .nav-item:nth-child(3) .nav-link {
		transition-delay: .3s;
	}
	.nav-list.active .nav-item:nth-child(4) .nav-link {
		transition-delay: .2s;
	}
	.nav-list.active .nav-item:nth-child(5) .nav-link {
		transition-delay: .1s;
	}
	/*reverse*/
	.nav-list .nav-item:nth-child(1) .nav-link {
		transition-delay: .1s;
	}
	.nav-list .nav-item:nth-child(2) .nav-link {
		transition-delay: .2s;
	}
	.nav-list .nav-item:nth-child(3) .nav-link {
		transition-delay: .3s;
	}
	.nav-list .nav-item:nth-child(4) .nav-link {
		transition-delay: .4s;
	}
	.nav-list .nav-item:nth-child(5) .nav-link {
		transition-delay: .5s;
	}
	.nav-link {
		text-decoration: none;
		display: block;
		margin: 16px 2rem 0 2rem;
		color: var(--main-font-color);
		transform: scale(0);
		transition: transform 650ms;
	}
	.nav-item {
		position: relative;
		line-height: 8rem;
		font-size: 1.7rem;
		transition-delay: .5s;
		visibility: hidden;
		margin-top: 0;
	}
}
