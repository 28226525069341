.jumbotron {
	height: 600px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 30px;
	/* font-size: calc(10px + 2vmin); */
	color: #e40001;
	/* font-size: clamp(1.2rem, 3vw, 3.4rem); */
	overflow: hidden;
	position: relative;
}
.jumbotron:before {
	content: "";
	position: absolute;
	z-index: -1;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: url('../../assets/images/8.png') 50% -1220px no-repeat;
	opacity: .4;
}
@media (max-width: 430px) {
	.jumbotron {
		height: 340px;
		max-width: 800px;
	}
}
.container {
	/* background: #fff; */
	width: 50%;
	height: 100%;
	z-index: 100;
	display: grid;
	position: relative;
	padding-bottom: 20px;
	z-index: 2;
}
.container:before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #fff;
	z-index: -1;
	opacity: .7;
}
.img {
	position: absolute;
	top: 15px;
	width: 80%;
	max-width: 450px;
	justify-self: center;
	left: 50%;
	transform: translateX(-45%);
}
.number {
	display: flex;
	justify-content: center;
	align-items: center;
	align-self: flex-end;
	gap: 20px;
	justify-self: center;
	width: 60%;
	overflow: hidden;
	position: relative;

	background: #e40001;
	color: #fff;
	text-decoration: none;
	font-size: clamp(1.2rem, 3.6vw, 3.6rem);
	font-style: italic;

	border: 0px solid #e40001;
	border-radius: 20px;
	padding: 10px;
	/* animation: bounce .8s linear infinite alternate; */
}
.shine {
	position: absolute;
	top: 0;
	left: -600px;
	width: 100%;
	height: 100%;
	background: rgba(255, 255, 255, 0.9);
	opacity: .1;
	transform: skew(20deg);
	animation-delay: 5s;
	animation: shine 2s linear infinite;
	box-shadow: 0 0 30px 30px rgba(255, 255, 255, 0.8);
}
@keyframes shine {
	from {
		left: -600px;
	}
	to {
		left: 400px;
	}
}
.number div {
	/* color: #000; */
	font-weight: 600;
}
@media (max-width: 768px) {
	.container {
		/* background: #fff; */
		width: 70%;
	}
}
