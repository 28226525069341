@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;1,200;1,300;1,500&display=swap');

body {
	/* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
		"Droid Sans", "Helvetica Neue", sans-serif; */
	font-family: 'Poppins', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
:root {
	--main-bg-color: #fff;
	--main-header-color: #fff;
	--main-font-color: #1b2025;
	/* --main-font-color: rgba(34, 33, 33); */
	--additional-color: #e40001;
	--footer-bkg: #333;
	/* --additional-color: #ff4f79; */
}
