.contact {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	text-align: center;
	hyphens: auto;
	padding: 0;
	padding-top: 7rem;
	padding-bottom: 30px;
	color: var(--main-font-color);
}
.h1 {
	font-weight: 700;
	font-size: 3rem;
	padding-bottom: 20px;
	color: var(--main-font-color);
	min-height: 10rem;
}
.h3 {
	font-size: 2rem;
}
.description {
	display: flex;
	flex-direction: column;
	width: 1000px;
	max-width: 75vw;
	margin: 0 auto;
	text-align: left;
}
.p {
	color: var(--additional-color);
	font-size: 1.9rem;
	font-weight: 600;
	margin-bottom: 1.6rem;
}

.kontakt {
	display: flex;
	flex-direction: row;
	justify-content: center;
	flex-wrap: wrap;
	align-content: center;
	align-items: center;
	margin: 20px auto;
	width: 95vw;
	min-height: 30vh;
	height: auto;
	border: 4px solid var(--additional-color);
	/* border: 1px solid rgba(146, 145, 145, 0.63); */
	padding: 5px;
	background-color: #fff;
	border-radius: 10px;
}

img.kontaktLogo {
	display: block;
	width: 400px;
	margin: auto;
}
.kontaktDescription {
	display: flex;
	flex-direction: column;
	width: 80vw;
	justify-content: space-around;
	margin-top: 5vh;
	/* margin-bottom: 5vh; */
	font-size: 2rem;

	font-weight: 600;
}

.kontaktDescription h2 {
	display: none;
	font-size: 2.6rem;
	color: var(--main-font-color);
}

.kontaktDescription .tel {
	/* border: 2px solid var(--additional-color); */
	padding: 10px;
	/* color: var(--main-font-color); */
	color: var(--additional-color);
	font-weight: 900;
	font-size: 4.0rem;
	text-decoration: none;
	cursor: pointer;
	margin: 15px auto;
	border-radius: 2.4rem;
}
.kontaktDescription .tel span {
	color: var(--main-font-color);
	font-size: 1.6rem;
	font-weight: 600;
	border-bottom: 1px solid var(--additional-color);
}

.more {
	max-width: 95vw;
	text-align: center;
}
.more-link {
	display: flex;
	cursor: pointer;
	text-decoration: none;
	color: var(--additional-color);
}
@media screen and (max-width: 830px) {
	.kontakt {
		flex-direction: column;
		max-width: 85vw;
	}
	.kontaktLogo {
		max-width: 50vw;
		margin-top: 50%-4vw;
	}
	.kontaktDescription {
		flex-direction: column;
	}
}
